import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import HeaderBack from '../components/header-back';
import Layout from '../components/layout';

const Articles = ({ data }) => {
  const articles = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Helmet>
        <title>Статьи</title>
        <meta
          name="description"
          content="Статьи и переводы статей для назидания верующих, для более глубого изучения Священного Писания."
        />
        <meta
          name="keywords"
          content="статьи, переводы, GBV, пусть истина и жизнь"
        />
      </Helmet>
      <HeaderBack small variant="simple">
        <h1 className="page-title">Статьи</h1>
      </HeaderBack>
      <div id="content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="main-content">
                <div className="blog">
                  {articles.map(({ id, excerpt, frontmatter }) => (
                    <div key={id} className="blog-grid">
                      <h3 className="blog-grid-title">
                        <Link to={frontmatter.slug}>{frontmatter.title}</Link>
                      </h3>
                      <p className="blog-grid-excerpt">
                        {frontmatter.description || excerpt}
                      </p>
                      <div className="blog-grid-meta">
                        {/* <span className="blog-grid-date">October 28, 2015</span> */}
                        <span className="blog-grid-author">
                          {frontmatter.author}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ArticlesQuery {
    allMarkdownRemark(sort: { fields: fileAbsolutePath, order: DESC }) {
      nodes {
        id
        excerpt
        frontmatter {
          slug
          title
          author
          description
        }
      }
    }
  }
`;

export default Articles;
